import React from 'react';
import Layout from '../components/Layout';
import SteeringForm from '../components/SteeringForm';
import TestimonialSlider from '../components/TestimonialSlider';
import Quote from '../components/Quote';

import twoBirdsDark from '../assets/images/bird&bird--dark.png';
import simmons from '../assets/images/simmons-and-simmons--dark.png';
import barclaysEagleLabs from '../assets/images/barclays-eagle-labs--dark.png';
import paulHastings from '../assets/images/paul-hastings--full--dark.png';
import ashurst from '../assets/images/ashurst--dark.png';
import addleshawGoddard from '../assets/images/addleshaw-goddard--dark.png';
import pinsentMasons from '../assets/images/pinsent-masons--dark.png';
import dentons from '../assets/images/dentons--dark.png';
import duffPhelps from '../assets/images/duff-phelps--dark.png';
import linklaters from '../assets/images/linklaters--dark.png';

import checkCircle from '../assets/images/check-circle.svg';
import steeringGroup from '../assets/images/steering-group.jpg';
import helgaButcher from '../assets/images/helga-butcher.jpg';

import ventures from '../assets/images/venturesLogo.svg';

const SteeringGroup = ({ path }) => (
  <Layout
    pageName="steering"
    path={path}
    title="Steering Group - Fibonacci"
    image="/metaimages/steering-group.jpg"
    description="We walk the talk."
  >
    <div className="banner">
      <div className="chip">STEERING GROUP</div>
      <h1>
        We walk the talk
      </h1>
    </div>
    <div className="steering-container">
      <div className="content">
        <div className="image-container">
          <img src={steeringGroup} className="image" alt="steering group" />
        </div>
        <p>
          Fibonacci has been informed by the legal industry’s
          brightest minds. Their innovation and desire to challenge
          the status-quo is imprinted in Fibonacci’s DNA.
          Interested in taking part? We’re looking for more movers
          and shakers who can help us develop best practices when it
          comes to efficient and productive legal services delivery.
          As a cloud-based solution, Fibonacci seeks to continuously
          improve based on real-life use and feedback from our Steering Group.
        </p>
        <p>
          <span className="check" />
          By being involved in Fibonacci’s
          {' '}
          <b>Steering Group</b>
          , you will:
        </p>
        <div className="item">
          <img className="check" src={checkCircle} alt="check" />
          <p>Be involved in developing best practice legal project management processes</p>
        </div>
        <div className="item">
          <img className="check" src={checkCircle} alt="check" />
          <p>Shape and influence Fibonacci’s development roadmap</p>
        </div>
        <div className="item">
          <img className="check" src={checkCircle} alt="check" />
          <p>
            Nominate priority features and be the first
            to test-drive these in Fibonacci’s testing environment
          </p>
        </div>
        <p>We look forward to welcoming you into our Steering Group!</p>
        <div className="logos">
          <p>Join our Steering Group</p>
          <div className="row">
            <img className="item" src={twoBirdsDark} alt="" />
            <img className="item" src={simmons} alt="" />
            <img className="item" src={barclaysEagleLabs} alt="" />
            <img className="item" src={paulHastings} alt="" />
            <img className="item" src={ashurst} alt="" />
            <img className="item" src={addleshawGoddard} alt="" />
          </div>
          <div className="row">
            <img className="item -lg" src={pinsentMasons} alt="" />
            {false && <img className="item" src={dentons} alt="" />}
            <img className="item" src={duffPhelps} alt="" />
            {false && <img className="item" src={linklaters} alt="" />}
          </div>
        </div>
        <div className="logos-sm">
          <p>Join our Steering Group</p>
          <img className="logo twoBirdsDark" src={twoBirdsDark} alt="" />
          <img className="logo simmons" src={simmons} alt="" />
          <img className="logo barclaysEagleLabs" src={barclaysEagleLabs} alt="" />
          <img className="logo paulHastings" src={paulHastings} alt="" />
        </div>
        <div className="logos-sm">
          <img className="logo ashurst" src={ashurst} alt="" />
          <img className="logo addleshawGoddard" src={addleshawGoddard} alt="" />
          <img className="logo pinsentMasons" src={pinsentMasons} alt="" />
        </div>
        <div className="logos-sm">
          {false && <img className="logo dentons" src={dentons} alt="" />}
          <img className="logo duffPhelps" src={duffPhelps} alt="" />
          {false && <img className="logo linklaters" src={linklaters} alt="" />}
        </div>
      </div>
      <SteeringForm initialPartnership="Steering Group" />
    </div>
    <div className="testimonial-container">
      <TestimonialSlider>
        <Quote
          classes="quote-responsive"
          author="Helga Butcher"
          position="Director, Legal Project Management"
          avatar={helgaButcher}
          logo={ventures}
          content="This is a unique chance for leading LPM minds to come together to shape best
          practice LPM methodology and to work with leading software developers to codify this
          framework into Fibonacci."
        />
      </TestimonialSlider>
    </div>
  </Layout>
);

export default SteeringGroup;
